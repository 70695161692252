import { Controller } from "stimulus"
import Tribute from "tributejs"
import Trix from "trix"

export default class extends Controller {
  static targets = [ "trix" ]

  connect() {
    this.editor = this.trixTarget.editor
    this.initializeTribute()
  }

  disconnect() {
    this.tribute.detach(this.trixTarget)
  }

  initializeTribute() {
    this.tribute = new Tribute({
        allowSpaces: true,
        lookup: 'name',
        values: this.fetchUsers,
    })
    this.tribute.attach(this.trixTarget)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
    this.trixTarget.addEventListener("tribute-replaced", this.replaced)
  }

  fetchUsers(text, callback) {
      fetch(`/dashboard/users/mentions.json?query=${text}`)
        .then(response => response.json())
        .then(users => callback(users))
        .catch(error => callback([]))
  }

  replaced(e) {
      let mentioned = e.detail.item.original
      let attachment = new Trix.Attachment({
          sgid: mentioned.sgid,
          content: mentioned.content
      })
      this.editor.insertAttachment(attachment)
      this.editor.insertString(" ")
  }

  _pasteHtml(html, startPos, endPos) {
      let position = this.editor.getPosition()
      this.editor.setSelectedRange([position - endPos, position])
      this.editor.deleteInDirection('forwards')
  }
}

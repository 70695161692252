import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["textarea", "characters" ]

  count() {
    const element = this.textareaTarget
    const text = element.value
    const countLabel = this.charactersTarget
    countLabel.innerHTML = text.length + "/1200 chars"
  }
}
import consumer from "./consumer"

$(function(){
  consumer.subscriptions.create({channel: "BusinessChannel", business_id: $(".main-content").attr("data-business-id")}, {
    connected() {
      // Called when the subscription is ready for use on the server
    },
  
    disconnected() {
      // Called when the subscription has been terminated by the server
    },
  
    received(data) {
      // Called when there's incoming data on the websocket for this channel

      switch(data.topic) {
        case 'text:created':
          processTextCreated(data)
          break;
        case 'conversation:updated':
          toggleInboxEmptyState(data);
          insertConversationSummary(data);
          break;
        default:
          break;
      }
      
    }
  });

})

function toggleInboxEmptyState(data) {

  let inbox = "#text-inbox-" + data.model.phone_line_id;

  if($(inbox).length == 0) {
    removeInboxEmptyDataset();
    createTextInbox();
  }
}

function removeInboxEmptyDataset() {
  $("#empty-inbox").remove();
}

function createTextInbox() {
  $( "#inbox-vertical" ).append("<ul id='text-inbox' class='list-group list-group-lg list-group-flush list border-0'>");
}

function insertConversationSummary(data){
  let convo = "#conversation-" + data.model.id
  var inbox = "#text-inbox-" + data.model.phone_line_id

  var list_item = $(data.markup);

  var current_convo_id = $(".main-content").data("current-conversation");

  reSortInbox(convo, list_item, inbox)
  reprocessUpdatedConversation(current_convo_id, data.model.id, list_item)
}



function reSortInbox(convo, list_item, inbox) {
  if($(convo).length) {
    $(convo).remove();
    $(inbox).prepend( list_item );
  } else {
    $(inbox).prepend( list_item);
  }
}


function reprocessUpdatedConversation(current_convo_id, updated_convo_id, list_item) {
  if(current_convo_id == updated_convo_id) {
    list_item.addClass("active text-white");
    
    let sender_id = "#sender-name-" + updated_convo_id;
    let sender_link = $(sender_id);
    sender_link.removeClass("text-dark");
    sender_link.addClass("text-white");

    let badge_id = "#unread-badge-" + updated_convo_id;
    let unread_badge = $(badge_id);
    unread_badge.removeClass("badge-primary");
    unread_badge.addClass("badge-white");
  } 
}

function processTextCreated(data) {
  let model = data.model
  let convo = "#conversation-" + model.conversation_id + "-texts"

  if($(convo).length) {
    $(convo).append( $(data.markup).hide().fadeIn('slow') );
    $(convo).stop ().animate ({
      scrollTop: $(convo)[0].scrollHeight
    });
  }
}
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import 'jquery'
import 'bootstrap'
import autosize from 'autosize'
import EmojiButton from '@joeattardi/emoji-button';
//import {Howl, Howler} from 'howler';

const {Howl, Howler} = require('howler');

var Inputmask = require('inputmask');

$(document).on('turbolinks:load', function(){ 
  autosize($('textarea'));
  scrollToBottom();
  maskPhoneNumberInput();

  document.body.addEventListener('ajax:success', function(event) {
    var detail = event.detail;
    var data = detail[0], status = detail[1], xhr = detail[2];

    scrollToBottom();
    clearTextMessageComposer();
  })

  $('.contact-link').on ('ajax:success', function(event, data, status, xhr){
    $('#contactDetailsModal').modal('show')
  });

  $('.employee-link').on ('ajax:success', function(event, data, status, xhr){
    $('#employeeDetailsModal').modal('show')
  });

  $('#user_assignments a').on('click', function (e) {
    e.preventDefault()
  })

  const emojiPickerButton = document.querySelector('#emoji');

  if (emojiPickerButton != null) {
    const picker = new EmojiButton();

    picker.on('emoji', emoji => {
      var textAreaVal = $('.text-area').val()
      $('.text-area').val( textAreaVal += emoji  )
    });
  
    emojiPickerButton.addEventListener('click', () => {
      picker.togglePicker(emojiPickerButton);
    });
  }


  $('.toast').toast({
    autohide: false
  })

})

$(document).ready(function(){

  $('#textContactModal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget) // Button that triggered the modal
    var phone_number = button.data('phone-number') // Extract info from data-* attributes
    console.log(phone_number)
    // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
    // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
    var modal = $(this)
    //modal.find('.modal-title').text('New message to ' + recipient)
    //modal.find('.modal-body input').val(recipient)
    modal.find('#text_composition_phone_number').val(phone_number)
  })

});

function maskPhoneNumberInput() {
  var im = new Inputmask({"mask": "(999) 999-9999"});
  im.mask('#text_composition_phone_number');
}

function clearTextMessageComposer() {
  if($('#text_message').length) {
    $('#text_message').val('');
    $('#text_message').focus();
  }
}

function scrollToBottom() {
  if($('.text-messages-list').length) {
    $('.text-messages-list').stop ().animate ({
      scrollTop: $('.text-messages-list')[0].scrollHeight
    });
  } 
}

$(function(){
  $('#composeMessageModal').on('shown.bs.modal', function (e) {
      $('#text_composition_phone_number').focus();
  });
});

addEventListener("direct-upload:initialize", event => {
  const { target, detail } = event
  const { id, file } = detail
  target.insertAdjacentHTML("beforebegin", `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename">${file.name}</span>
    </div>
  `)
})

addEventListener("direct-upload:start", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.remove("direct-upload--pending")
})

addEventListener("direct-upload:progress", event => {
  const { id, progress } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-${id}`)
  progressElement.style.width = `${progress}%`
})

addEventListener("direct-upload:error", event => {
  event.preventDefault()
  const { id, error } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--error")
  element.setAttribute("title", error)
})

addEventListener("direct-upload:end", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--complete")
})

import "controllers"

require("trix")
require("@rails/actiontext")
import { Controller } from "stimulus"
import autosize from 'autosize'

export default class extends Controller {
  static targets = ["replies", "new_quick_reply"]

  reply(ev) {
      let text_area = document.getElementById("text_message")
      text_area.value = ev.currentTarget.dataset.message
      autosize.update(text_area)
  };

  newQuickReply(ev) {
    console.log("THIS IS WHERE YOU LAUNCHES A MODAL!")
  }
}
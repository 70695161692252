import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkbox", "button"]

  connect() {
    this.buttonTarget.disabled = true;
  }

  validateForm() {
      let checkCount = 0;
      for(let checkbox of this.checkboxTargets) {
        if(checkbox.checked) {
          checkCount++;
        }
      }
      this.toggleSubmitButton(checkCount);
  }

  toggleSubmitButton(count) {
    if(count >= 2) {
      this.buttonTarget.disabled = false;
    } else {
      this.buttonTarget.disabled = true;
    }
  }


}